.about-image img {
    box-shadow: 0px 2px 24px 0px cyan;
    border: 2.5px solid lightcyan;
    border-radius: 1.5%;
    max-width: 100%;
}

.about-image {
    max-width: 50%;
    padding: 2.5%;
}

.about-content {
    max-width: 50%;
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-width: 100%;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.about {
    height: 100%;
    width: 100%;
    margin: 0;
}

@media (max-width: 960px) {
    .about-container {
        flex-direction: column;
        
    }

    .about-content,.about-image{
        max-width: 85%;
    }
}
.footer {
    /*Display stuff*/
    display: flex;
    align-items: center;
    /*Positioning stuff*/
    left: 0;
    bottom: 0;
    z-index: 2147483647;
    /*Box model stuff*/
    width: 100%;
    max-height: 36px;
    /*Typography stuff*/
    text-align: center;
    color: white;
    padding-top: 0.25%;
    padding-bottom: 0.25%;
    flex-wrap: wrap-reverse;
    margin-top: 2.5%;
    background-color: rgba(0, 0, 0, 0.75);
}

.footer-social {
    /*Box model stuff*/
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    max-height: 100%;
}

.footer-content {
    /*Box model stuff*/
    margin: auto;
    min-width: 50%;
    max-height: 100%;
}

.footer-icon {
    cursor: pointer;
    max-height: 36px;
    max-width: 36px;
}

@media only screen and (max-width: 650px) {
    .footer-content{
        width: 100%;
    }
    .footer-social{
        justify-content: space-evenly;
        width: 100%;
    }
}
@media only screen and (max-width: 420px) {
    .footer-content{
        display: none;
    }
}
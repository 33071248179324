/* The slider itself */
.slider {
  max-width: 75%;
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 5px;
  /* Specified height */
  background: #A9ACA9;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  margin-left: 1.5%;
  margin-right: 1.5%;
  --track-color: rgba(155, 0, 0, 0.8);
  --track-height: 10px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  cursor: pointer;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 5px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  background: lightgoldenrodyellow; /* Green background */
  cursor: pointer;
  /*
  margin is used here because chrome/edge seem to not center the slider
  thumnb to the track
  */
  margin-top: 0px;
}

.slider::-moz-range-thumb {
  background-color: lightgoldenrodyellow;
  width: 5px;
  height: 10px;
  border: none;
  border-radius: 0;
}

.slider::-moz-range-thumb:hover{
/*Box model stuff*/
  border: none;
  background: #bfc3ba;
/*Miscellaneous*/
  /* Slider handle height */
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.sider:hover::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 5px;
  /* Set a specific slider handle width */
  height: 10px;
  /* Slider handle height */
  background: red;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(155, 0, 0, 0.8);
}

.slider:focus::-webkit-slider-runnable-track {
  background: rgba(155, 0, 0, 0.8);
}

.slider::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(155, 0, 0, 0.8);
  border-radius: 1.3px;
}

.slider::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

.slider::-ms-fill-lower {
  background: rgba(155, 0, 0, 0.8);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.slider:focus::-ms-fill-lower {
  background: rgba(155, 0, 0, 0.8);
}

.slider::-ms-fill-upper {
  background: rgba(155, 0, 0, 0.8);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.slider:focus::-ms-fill-upper {
  background: rgba(155, 0, 0, 0.8);
}

/*chrome safari*/
.slider::before {
  content: '';
  height: 5px;
  width: 100px;
  background-color: black;
}

.slider::-moz-range-progress{
  background: rgba(255, 0, 0, 0.8);
  height: 10px;
}


@media only screen and (max-width: 600px) {
  .slider{
    max-width: 70%;
  }
}

@media only screen and (max-width: 420px) {
  .slider{
    min-width: 95%;
  }
}
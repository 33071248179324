.contact-form-wrapper{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1.5%;
}

@media only screen and (max-width: 420px){
    .contact-form-wrapper{
        margin-left: 0%;
        margin-right: 0%;
    }
}
.youtube-container {
    text-align: center;
}

.home-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: lightcyan;
    font-family: 'Bebas Neue';
}

.video-container {
    max-width: 820px;
    margin: auto;
    padding: 0 1.5rem;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    border: 2.5px solid lightcyan;
    box-shadow: 0px 2px 24px 0px cyan;
    box-sizing: border-box;
    border-radius: 1.5%;
}

.embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-container h1,
.home-container h2,
.home-container h3 {
}

.title {
    min-width: 100%;
}

.email-signup-wrapper{
    margin-top: 1.5%;
}
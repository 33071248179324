.blog-container {
  /*Positioning stuff*/
  position: relative;
  /*Box model stuff*/
  /*Typography stuff*/
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  text-align: center;
  /*Miscellaneous*/
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 0px white;
  width: 280px;
  margin-bottom: .5rem;
  margin-top: .5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  color: black;
  background-color: whitesmoke;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  
}

.large-card {
  width: 580px;
}

.blog-container span {
  top: -10px;
  left: -10px;
  position: absolute;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blog-container span::before {
  content: 'Latest Post';
  position: absolute;
  width: 150%;
  height: 40px;
  background-color: hotpink;
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: yellow;
  font-weight: bold;
  font-size: large;
  letter-spacing: 0.1rem;
  box-shadow: 0 5px 10px black;
}

.blog-container span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: yellow;
  z-index: -1;
  box-shadow: 140px -140px darkgray;
}



.container:hover {
  box-shadow: 0px 1px 4px 0px whitesmoke;
}

.card-image {
  width: 100%;
  height: 10rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
  background-color:black;
}

.title {
  font-size: 1.5rem;
}

.published {
  color: lightgray;
  text-decoration: underline;
}

.labels {
  color: darkgray;
}

.hvr-bounce-to-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hvr-bounce-to-bottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-bounce-to-bottom:hover,
.hvr-bounce-to-bottom:focus,
.hvr-bounce-to-bottom:active {
  color: white;
  border-color: lightgray;
}

.hvr-bounce-to-bottom:hover:before,
.hvr-bounce-to-bottom:focus:before,
.hvr-bounce-to-bottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
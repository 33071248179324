:root {
  --primary: white;
  --secondary: #ff47a9;
  --tertiary: #FEFF00;
  --background: black;
  --primary-soft: lightgray;
  --secondary-soft: hotpink;
  --tertiary-soft: lightyellow;
  --background-soft: darkgray;
}

.topnav {
  --navHeight: 24px;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  min-height: var(--navHeight);
  z-index: 2147483647;
}

.topnav a {
  float: left;
  display: block;
  color: var(--primary);
  height: var(--navHeight);
  text-align: center;
  padding-top: calc(var(--navHeight)/8);
  padding-bottom: calc(var(--navHeight)/8);
  padding-right: calc(var(--navHeight)/2);
  padding-left: calc(var(--navHeight)/2);
  font-family: 'Bebas Neue', cursive;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
}

.active {
  background-color: var(--secondary);
  color: var(--primary);
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
/*Box model stuff*/
  min-height: var(--navHeight);
  margin: 0;
  padding-top: calc(var(--navHeight)/8);
  padding-bottom: calc(var(--navHeight)/8);
  padding-right: calc(var(--navHeight)/2);
  padding-left: calc(var(--navHeight)/2);
  border: none;
  outline: none;
  background-color: inherit;
/*Typography stuff*/
  font-family: 'Bebas Neue', cursive;
  font-size: 18px;
  text-align: center;
  color: var(--primary);
}

.dropdown-content {
  display: none;
  overflow: hidden;
  position: absolute;
  background-color: var(--primary);
  min-width: calc(var(--navHeight) * 4);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0 15px 15px 15px
}

.dropdown-content a {
  float: none;
  color: var(--background);
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: var(--secondary-soft);
  color: var(--primary);
}

.dropdown-content a:hover {
  background-color: var(--primary-soft);
  color: var(--background);
}

.show {
  display: block;
}

@media screen and (max-width: 600px) {
  .topnav {
    background-color: black
  }

  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .dropdown-content {
    border-radius: 0;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }

  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.dropdown-icon {
  vertical-align: middle;
}